import Vue from 'vue'
import App from './App.vue'
import router from './router'// 引入vue-router
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'// 引入vuex
import axios from 'axios'
import moment from 'moment'

// 使用富文本

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'

import 'quill/dist/quill.snow.css'

import 'quill/dist/quill.bubble.css'

// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
// import "@wangeditor/editor/dist/css/style.css"
ElementUI.Dialog.props.closeOnClickModal.default = false; // 全局关闭点遮罩关闭弹框
// 全局过滤器
// Vue.prototype.$moment = moment
Vue.filter('dateFmt', (input, formatString = 'YYYY-MM-DD') => {
  // es5函数参数设置默认值
  // const lastFormatString = formatString ||

  // moment(input) 把时间字符串转成时间对象
  // format(formatString) 把时间对象，按照指定格式，格式化成符合条件的字符串
  return moment(input).format(formatString)
})// 导入axios

Vue.use(VueQuillEditor) // 注册富文本编辑器
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 使用axios
Vue.prototype.axios = axios
// axios携带token
// 请求拦截器，在请求头中加token
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = localStorage.getItem('Authorization')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

Vue.use(ElementUI)
Vue.config.productionTip = false
// 进入页面之前判断用户是否登录
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
//     if (localStorage.getItem('token')) { // 获取用户登录信息
//       next({
//         path: '/'
//       })
//     } else {
//       next({
//         path: '/login'
//       })
//     }
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    next()
  } else {
    const token = localStorage.getItem('Authorization')
    if (token) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
