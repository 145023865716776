import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/visitor',
    component: () => import('@/views/Main/Visitor Login/visitor.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitoreletterapp',
    component: () => import('@/views/Main/Visitor Login/visitoreletterapp.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorhanding',
    component: () => import('@/views/Main/Visitor Login/visitorhanding.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visihand',
    component: () => import('@/views/Main/Visitor Login/visihand.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorinvioce',
    component: () => import('@/views/Main/Visitor Login/visitorinvioce.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorletter',
    component: () => import('@/views/Main/Visitor Login/visitorletter.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorindemnity',
    component: () => import('@/views/Main/Visitor Login/visitorindemnity.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/newbuilt',
    component: () => import('@/views/Main/Visitor Login/newbuilt.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorreview',
    component: () => import('@/views/Main/Visitor Login/visitorreview.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorhand',
    component: () => import('@/views/Main/Visitor Login/visitorhand.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/visitorpayment',
    component: () => import('@/views/Main/Visitor Login/visitorpayment.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'connectionPay',
    path: '/connectionPay',
    component: () => import('@/views/connection/pay.vue'),
    meta: {
      requireAuth: true
    }
  },

  {
    path: '/',
    name: 'homepage',
    component: () => import('@/views/homePage/App.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/',
    component: () => import('@/views/Menu.vue'),
    hidden: false,

    children: [
      {
        path: '/work',
        component: () => import('@/views/Main/work.vue'),
        meta: { title: '工作台' }
      },
      {
        path: '/letter',
        component: () => import('@/views/Main/letter.vue'),
        meta: { title: '保函查验' }
      },
      {
        path: '/guarantees',
        component: () => import('@/views/Main/guarantee/guarantees.vue'),
        meta: { title: '保函列表' }
      },
      {
        path: '/myGuarantee',
        component: () => import('@/views/Main/guarantee/myGuarantee.vue'),
        meta: { title: '我的出函' }
      },
      {
        path: '/signContract',
        component: () => import('@/views/Main/guarantee/signContract.vue'),
        meta: { title: '签约文件' }
      },
      {
        path: '/project',
        component: () => import('@/views/Main/guarantee/project.vue'),
        meta: { title: '项目列表' }
      },
      {
        path: '/seal',
        component: () => import('@/views/Main/seal.vue'),
        meta: { title: '印章管理' }
      },
      {
        path: '/information',
        component: () => import('@/views/Main/information.vue'),
        meta: { title: '信息设置' }
      },
      {
        path: '/financial',
        component: () => import('@/views/Main/financial.vue'),
        meta: { title: '发票管理' }
      },
      {
        path: '/order',
        component: () => import('@/views/Main/order.vue'),
        meta: { title: '订单管理' }
      },
      {
        path: '/invoiceHead',
        component: () => import('@/views/Main/invoiceHead.vue'),
        meta: { title: '发票抬头' }
      },
      {
        path: '/message',
        component: () => import('@/views/Main/message.vue'),
        meta: { title: '消息中心' }
      },
      {
        path: '/newletter',
        component: () => import('@/views/Main/guarantee/letter/newLetter.vue'),
        meta: { title: '新建保函' }
      },
      {
        path: '/payState',
        component: () => import('@/views/Main/guarantee/letter/payState.vue'),
        meta: { title: '企业认证' }
      },
      {
        path: '/filling',
        component: () => import('@/views/Main/guarantee/list/filling.vue'),
        meta: { title: '资料填写' }
      },
      {
        path: '/indemnity',
        component: () => import('@/views/Main/guarantee/letter/indem.vue'),
        meta: { title: '保函查询' }
      },
      {
        path: '/role',
        component: () => import('@/views/Main/system/role.vue'),
        meta: { title: '角色管理' }
      },
      {
        path: '/mess',
        component: () => import('@/views/Main/guarantee/letter/mess.vue'),
        meta: { title: '发布公告' }
      },
      {
        path: '/member',
        component: () => import('@/views/Main/member.vue'),
        meta: { title: '公司成员管理' }
      },
      {
        path: '/material',
        component: () => import('@/views/Main/guarantee/list/Material.vue'),
        meta: { title: '材料管理 ' }
      },
      {
        path: '/management',
        component: () => import('@/views/Main/management.vue'),
        meta: { title: '公司信息管理' }
      },
      {
        path: '/blacklist',
        component: () => import('@/views/Main/guarantee/list/blacklist.vue'),
        meta: { title: '不良行为名单' }
      },
      {
        path: '/address',
        component: () => import('@/views/Main/guarantee/list/address.vue'),
        meta: { title: '地址管理' }
      },
      {
        path: '/proje',
        component: () => import('@/views/Main/guarantee/letter/proje.vue'),
        meta: { title: '项目信息' }
      },
      {
        path: '/handle',
        component: () => import('@/views/Main/guarantee/list/handle.vue'),
        meta: { title: '代出函' }
      },

      {
        path: '/surrenderSettle',
        component: () => import('@/views/Main/guarantee/surrenderSettle.vue'),
        meta: { title: '退保与理赔' }
      },
      {
        path: '/stayPay',
        component: () => import('@/views/Main/guarantee/list/stayPay.vue'),
        meta: { title: '待支付' }
      },
      {
        path: '/hand',
        component: () => import('@/views/Main/guarantee/letter/hand.vue'),
        meta: { title: '出函代办-新建保函' }
      },
      {
        path: '/gener',
        component: () => import('@/views/Main/guarantee/letter/gener.vue'),
        meta: { title: '出函代办-新建保函' }
      },

      {
        path: '/approval',
        component: () => import('@/views/Main/guarantee/list/approval.vue'),
        meta: { title: '企业认证审批' }
      },
      {
        path: '/letterApp',
        component: () => import('@/views/Main/guarantee/list/letterapp.vue'),
        meta: { title: '保函审批' }
      },
      {
        path: '/applica',
        component: () => import('@/views/Main/guarantee/list/appplica.vue'),
        meta: { title: '保函审核' }
      },
      {
        path: '/payState',
        component: () => import('@/views/Main/guarantee/letter/payState.vue'),
        meta: { title: '信息认证' }
      },
      {
        path: '/infor',
        component: () => import('@/views/Main/guarantee/letter/infor.vue'),
        meta: { title: '信息认证' }
      },
      {
        path: '/review',
        component: () => import('@/views/Main/guarantee/list/review.vue'),
        meta: { title: '保函提交审核' }
      },
      {
        path: '/payment',
        component: () => import('@/views/Main/guarantee/list/payment.vue'),
        meta: { title: '保函支付阶段' }
      },
      {
        path: '/handing',
        component: () => import('@/views/Main/guarantee/list/handling.vue'),
        meta: { title: '保函办理阶段' }
      },
      {
        path: '/guaranteeInvoice',
        component: () => import('@/views/Main/guarantee/list/guaranteeInvoice.vue'),
        meta: { title: '保函发票' }
      },
      {
        path: '/PlatformInformation',
        component: () => import('@/views/Main/PlatformInformation.vue'),
        meta: { title: '平台信息设置' }
      },
      {
        path: '/guratterDetails',
        component: () => import('@/views/Main/guarantee/letter/guratterDetails.vue'),
        meta: { title: '保函详情' }
      },
      {
        path: '/commission',
        component: () => import('@/views/Main/guarantee/letter/commission.vue'),
        meta: { title: '出函代办-详情' }
      },
      {
        path: '/check',
        component: () => import('@/views/Main/guarantee/letter/check.vue'),
        meta: { title: '查看保函' }
      },
      {
        path: '/instructions',
        component: () => import('@/views/Main/system/instructions.vue'),
        meta: { title: '投标须知设置' }
      },
      {
        path: '/roleManager',
        component: () => import('@/views/Main/system/roleManager.vue'),
        meta: { title: '用户管理' }
      },
      {
        path: '/messageCenter',
        component: () => import('@/views/Main/system/messageCenter.vue'),
        meta: { title: '留言中心' }
      },
      {
        path: '/gather',
        component: () => import('@/views/Main/system/gather.vue'),
        meta: { title: '保函类别' }
      },
      {
        path: '/prise',
        component: () => import('@/views/Main/guarantee/letter/prise.vue'),
        meta: { title: '公司详情' }
      },
      {
        path: '/apprise',
        component: () => import('@/views/Main/guarantee/letter/apprise.vue'),
        meta: { title: '公司编辑' }
      },
   
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
